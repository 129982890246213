<template>
  <div>
    <div class="ddic">
        <p class="h6"><b-icon icon="newspaper"></b-icon> 發送紀錄</p>
        <a-table
            :columns="columns"
            :row-key="record => record.id"
            :data-source="data"
            :pagination="pagination"
            :loading="loading"
            @change="handleTableChange"
            bordered
        >

            <template slot="typ" slot-scope="typ"> 
            <div v-show="typ=='alert'"><b-icon icon="bell-fill" variant="warning" font-scale="1.5" ></b-icon>&ensp;訊息</div>
            <div v-show="typ=='mess'"><b-icon icon="chat-quote-fill" variant="success" font-scale="1.5"></b-icon>&ensp;公告</div>
            </template>           
            <template slot="created_at" slot-scope="created_at"> {{ fdateformat(created_at) }}</template>
            <a slot="action" slot-scope="obj">
            <router-link :to="{name:'Record_detail',params:{id:obj.id}}">詳細內容..</router-link>
            </a>
        </a-table>
    </div>
  </div>
  
</template>
<script>
import { messService } from '../../../_services/'
import * as moment from "moment/moment";
import { mapState } from "vuex";

const queryData = params => {
  //console.log("params:",params)
  return messService.finditemslist(params.page,params.pid) 

};  

const updatedata = obj => {
  return  messService.upstatus(obj)
}
const columns = [
  {
    title: '訊息類別',
    dataIndex: 'typ',
    width: '15%',    
    scopedSlots: { customRender: 'typ' },    
  },  
  {
    title: '收件者',
    dataIndex: 'tname',
    sorter: false,
    width: '15%',
    scopedSlots: { customRender: 'tname' },
  },
  {
    title: '標題',
    dataIndex: 'title',
    width: '40%',
  },   
  {
    title: '發送日期',
    dataIndex: 'created_at',
    scopedSlots: { customRender: 'created_at' },    
    width: '15%',
  }, 
  { 
    title: '內文', 
    dataIndex: '', 
    key: 'id', 
    scopedSlots: { customRender: 'action' } ,
    width: '15%',
    },   

];

export default {
  name: 'Info_center',    
  data() {
    return {
      data: [],
      pagination: {defaultPageSize:4},
      loading: false,
      columns,
    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
  },
  mounted() {
    this.inid()
  },
  methods: {
    inid(){
        this.fetch({results:4,page:1,pageSize: 4,pid:this.user.data.user.id });
    },
    handleTableChange(pagination, filters, sorter) {
      //console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      //console.log(this.pagination);
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        pid: this.user.data.user.id,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      queryData({
        //results: 4,
        ...params,
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.info.totail;
        //pagination.total = 200;
        this.loading = false;
        this.data = data.results;
        this.pagination = pagination; 
      });
    },
    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },
    updatestatus(ids){
      let obj ={id: ids}
      updatedata(obj).then(({data}) =>{
        if(data.rs=='done'){
          this.data.find(obj => obj.id == data.id).states = "Y"
        }
      });
    }
  },
};
</script>
<style scoped>
.ddic{
    padding:20px;
    border-radius: 15px;
    text-align: left;
    border-width:0px;
    margin: 25px auto;
    border-style:dotted;
    border-color:#e0dedc;
	transition: all .3s ease;
}
</style>