<template>
  <div class="container-xxl">
        <h6><b-icon icon="bookmarks-fill"></b-icon> 公告管理</h6>
            <br>
        <div>
          <b-tabs content-class="mt-3" v-on:activate-tab="tabActivated" fill>
            <b-tab title="建立公告" :active="actives.page1">
            <Createdecs @done="done" ref="con1"  />
            </b-tab>
            <b-tab title="公告檔" :active="actives.page2">            
            <Listdece ref="con2" @done="done" />
            </b-tab>
            <b-tab title="發送紀錄" :active="actives.page3">
            <Recorddece ref="con3" @done="done" /> 
            </b-tab>            
          </b-tabs>
        </div>
  </div>
  
</template>
<script>
import * as moment from "moment/moment";
import Createdecs from "./controlbulletin/Createdecs.vue" 
import Listdece from "./controlbulletin/Listdece.vue" 
import Recorddece from "./controlbulletin/Recorddece.vue"
import { mapState } from "vuex"; 
 


export default {
  name: 'Info_contorl', 
  components: {
    Createdecs,
    Listdece,
    Recorddece,
  },     
  data() {
    return {
      data: [],
      actives:{page1:false,page2:true,page3:false}
    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
  },
  mounted() {
    //this.fetch({results:4,page:1,pageSize: 4,pid:this.user.data.user.id });
  },
  methods: {

    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },

  //tabActivated(newTabIndex,oldTabIndex, event){ 
  tabActivated(newTabIndex){      
      if(newTabIndex==0){
        this.actives.page2=false
        this.actives.page3=false
      }else if(newTabIndex==1){
        this.$refs.con1.clear()
        this.actives.page1=false
        this.actives.page3=false
      }else if(newTabIndex==2){
        this.$refs.con1.clear()
        this.$refs.con3.inid()
        this.actives.page1=false
        this.actives.page2=false        
      }
      //event.preventDefault();
  }, 

    done(i,rs){
      if(i==1){
        this.actives.page1=true
        this.$refs.con1.injection(rs)
      }else if(i==2){
        this.$refs.con2.reload(rs)
        this.actives.page2=true
      }else if(i==3){
        this.actives.page3=true      
      }      

    },   
  },
};
</script>