<template>
  <div>
    <div class="ddic">
        <p class="h6"><b-icon icon="calendar-range-fill"></b-icon> 公告檔(發送)</p>
        <a-table
            :columns="columns"
            :row-key="record => record.id"
            :data-source="data"
            :pagination="pagination"
            :loading="loading"
            @change="handleTableChange"
            bordered
        >
            <template slot="type" slot-scope="type"> 
            <div v-show="type=='alert'"><b-icon icon="bell-fill" variant="warning" font-scale="1.5" ></b-icon>&ensp;訊息</div>
            <div v-show="type=='mess'"><b-icon icon="chat-quote-fill" variant="success" font-scale="1.5"></b-icon>&ensp;公告</div>            
            </template>                       
            <template slot="created_at" slot-scope="created_at"> {{ fdateformat(created_at) }}</template>
            <a slot="action" slot-scope="obj">
            <router-link :to="{name:'Info_contorl_detail',params:{id:obj.id}}">檢視</router-link>&ensp;|&ensp;
            <router-link :disabled="obj.states=='Y'" @click.native="adjusts(obj)" to="#">調整</router-link>&ensp;|&ensp;
            <router-link @click.native="sMessage(obj)" to="#">發送</router-link>&ensp;|&ensp;
            <router-link @click.native="DMessage(obj)" to="#">刪除</router-link>
            </a>
        </a-table>

        <b-modal ref="my-loading" id="modal-2" size="lg" title="發送公告" @hide="onHide" centered hide-footer>
          <div class="text-center">  
             <p class="h6"><b-icon-vinyl-fill></b-icon-vinyl-fill>人員清單</p>                        
          </div>      
          <div class="text-left">
                  <a-input-search placeholder="請輸入查詢名稱" style="margin: 10px;width: 200px" enter-button @search="onSearch" /> 
          </div>    
          <div class="overflow-auto" style="height: 410px;width:100%;" id="style-3">
            <div class="text-left">                      
                <a-table
                    :columns="mcolumns"
                    :row-key="record => record.id"
                    :data-source="udata"
                    :pagination="upagination"
                    :loading="uloading"
                    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                    @change="uhandleTableChange"
                    bordered
                >
                    <template slot="pri_url" slot-scope="pri_url"> 
                        <b-avatar :src="pri_url" variant="primary" text="" size="2rem" class="align-baseline"></b-avatar> 
                    </template> 
                    <template slot="username" slot-scope="record">
                <div>
                  <span
                    v-if="record.username != ''"
                    style="color: #f08c3c"
                    v-text="record.username"
                  ></span>
                </div>
              </template>
              <template slot="display_name" slot-scope="record">
                <div>
                  <span
                    v-if="record.display_name != ''"
                    style="color: #2c7190"
                    v-text="record.display_name"
                  ></span>
                </div>
              </template>                                        
                </a-table>
            </div>
          </div>
          <div class="text-center">  
             <a-button class="bton" size="large" @click="messagesend()" type="primary" shape="round" :disabled="selectedRowKeys.length==0" block :loading="iconLoading">發送公告</a-button>                        
          </div>          
        </b-modal> 

    </div>
  </div>
  
</template>
<script>
import { cmessService } from '../../../_services'
import * as moment from "moment/moment";
import { mapState,mapMutations } from "vuex";

const queryData = params => {
  //console.log("params:",params)
  return cmessService.finditems(params.page,params.pid) 
};  

const updatedata = obj => {
  return  cmessService.upstatus(obj)
}

const queryuser = params => {
  return cmessService.findusListName(params.page,params.name)
}

const postMsg = obj => {
  return  cmessService.postMsg(obj)
}

const delMsg = params =>{
  return cmessService.dMsg(params)
}

const columns = [
  {
    title: '訊息',
    dataIndex: 'type',
    width: '8%',    
    scopedSlots: { customRender: 'type' },    
  },    
  {
    title: '標題',
    dataIndex: 'title',
    width: '30%',
  },   
  {
    title: '刊登日期',
    dataIndex: 'created_at',
    scopedSlots: { customRender: 'created_at' },    
    width: '10%',
  }, 
  { 
    title: '檢視 | 調整 | 發送 | 刪除', 
    dataIndex: '', 
    key: 'id', 
    scopedSlots: { customRender: 'action' } ,
    width: '15%',
    },   

];

const mcolumns = [
  {
    title: "圖像",
    dataIndex: "pri_url",
    width: "25%",
    scopedSlots: { customRender: "pri_url" },
  },
  {
    title: "註冊帳號",
    dataIndex: "",
    scopedSlots: { customRender: "username" },
    width: "30%",
  },
  {
    title: "Line顯示名稱",
    dataIndex: "",
    scopedSlots: { customRender: "display_name" },
    width: "30%",
  },  

];

export default {
  name: 'Listdece',    
  data() {
    return {
      data: [],
      udata: [],
      pagination: {defaultPageSize:4},
      upagination: {defaultPageSize:4},
      name:"all",      
      loading: false,
      uloading: false,
      selectedRowKeys: [], 
      sendata:{},
      columns,
      mcolumns,
      iconLoading: false,
    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
    ...mapState("ws", ["wsNotify","wsState"])
  },
  mounted() {
    let rs =null
    this.reload(rs)
  },
  methods: {
    ...mapMutations('ws',['sendMessage']),
    reload(rs){
        if(rs==null){
          this.fetch({results:4,page:1,pageSize: 4,pid:this.user.data.user.id });
        }else{
          this.fetch(rs);
        }
    },

    inid(){
      let rs =null
      this.reload(rs)
    },   

      async messagesend() {
            this.iconLoading = true 
            let tmp =[]
            for(var obj in this.selectedRowKeys) {
              let val = {id: this.selectedRowKeys[obj]}
              tmp.push(val);
            }
            let sdata = {
                id : this.sendata.id,
                type:this.sendata.type,
                pid: this.sendata.pid,
                title:this.sendata.title,
                states:this.sendata.states,
                rmg: "",
                tomenss: tmp
              }
            //this.sendMessage(JSON.stringify(sdata)) 
             let res= await this.MessageSend(sdata); 
            if(res.data.code==200){
              this.data.find(obj => obj.id == this.sendata.id).states = "Y"
            setTimeout(() => {   
                this.iconLoading = false 
                this.selectedRowKeys =[]
                clearInterval();
            }, 1000);
            }


        },
        
    MessageSend(obj) {
      return new Promise(resolve => {
        postMsg(obj).then((rs) =>{resolve(rs)});
      });
    },        

    handleTableChange(pagination, filters, sorter) {
      //console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      //console.log(this.pagination);
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        pid: this.user.data.user.id,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    }, 
    fetch(params = {}) {
      this.loading = true;
      queryData({
        //results: 4,
        ...params,
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.info.totail;
        //pagination.total = 200;
        this.loading = false;
        this.data = data.results;
        this.pagination = pagination; 
      });
    },
    fdateformat(val){
        return moment(val).format("YYYY-MM-DD"); 
    },
    adjusts(rs){
      if(this.pagination.current==undefined){
        rs.page =1
      }else{
        rs.page = this.pagination.current
      }
      rs.results = this.pagination.defaultPageSize
      //console.log(rs)
      this.$emit('done',1,rs) 
    },
    updatestatus(ids){
      let obj ={id: ids}
      updatedata(obj).then(({data}) =>{
        if(data.rs=='done'){
          this.data.find(obj => obj.id == data.id).states = "Y"
        }
      });
    },
    sMessage(rs){
      this.sendata = rs
      this.usfetch({ results: 4, page: 1, pageSize: 4 ,name: this.name});
      this.$refs['my-loading'].show()
    },

    async DMessage(obj){
      let my = this
      let did  = obj.id
      this.$confirm({
        title: '您確定要刪除該項目?',
        content: '確定請按下 OK 按鈕, 取消請按下Cancel',
       async onOk() {
          let res= await my.DeleteMessage(did); 
          if(res.data.data==true){
          let rs =null
          my.reload(rs)         
          }
        },
        onCancel() {},
      });
    },

    DeleteMessage(id) {
      return new Promise(resolve => {
        delMsg(id).then((rs) =>{resolve(rs)});
      });
    },        

    onHide(evt) {
        if(evt.trigger === "backdrop"){
            evt.preventDefault();
        }
    },

    onSelectChange(selectedRowKeys) {
      //console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },  
    
    
    usfetch(params = {}) { 
      this.uloading = true;
      queryuser({
        //results: 4,
        ...params,
      }).then(({ data }) => {
        const pagination = { ...this.upagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.info.totail;
        //pagination.total = 200;
        this.uloading = false;
        this.udata = data.results;
        this.upagination = pagination; 
      });
    }, 
    
    uhandleTableChange(pagination, filters, sorter) {
      //console.log(pagination);
      const pager = { ...this.upagination };
      pager.current = pagination.current;
      this.upagination = pager;
      //console.log(this.pagination);
      this.usfetch({
        results: pagination.pageSize,
        name: this.name,         
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    }, 
    
    async onSearch(value) {
          if(value==""){
            this.name = "all"
          }else{
            this.name = value
          }
          await this.usfetch({results:4,page:1,pageSize: 4,name: this.name});
        },     


  },
};
</script>
<style scoped>
.ddic{
    padding:20px;
    border-radius: 15px;
    text-align: left;
    border-width:0px;
    margin: 25px auto;
    border-style:dotted;
    border-color:#e0dedc;
	transition: all .3s ease;
}
</style>

