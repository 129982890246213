<template>
    <div>   
            <b-card
                header=""
                header-border-variant="light"
                header-bg-variant="light"
                align="center"
                class="card-3"            
                >
               
            <b-card-body  class="overflow-auto">
            <b-card-text> 
            <a-alert v-if="errors!==''" :message="errors" :type="mtyps" show-icon /> 
            <a-alert v-if="formdata" message="資料填妥, 確認執行" type="success" /> 
            <div class="text-left" style="padding:5px 50px 5px 50px;">
            <p class="h6"><b-icon-pencil-square></b-icon-pencil-square> 公告內容</p>
            <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;">
            <b-form @submit="onSubmit" @reset="onReset">  
                <b-form-group id="input-group-1" style="width:350px;" label="訊息類別*" label-for="input-1">
                    <b-form-select v-model="form.type" :state="typeState"  id="radio-group-1" class="mb-3">
                        <template #first>
                            <b-form-select-option :value="null" disabled>-- 請選擇訊息類別 --</b-form-select-option>
                            <b-form-select-option v-for="(result,i) in typedata" :key="i" :value="result.typ">{{result.name}}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>                   
               <b-form-group id="input-group-2" label="標題*" label-for="input-2">
                    <b-form-input
                        id="input-2"
                        v-model="form.title"
                        type="url"
                        placeholder="輸入標題"
                        autocomplete="off"
                        :state="titleState"                        
                        required
                    ></b-form-input>
                </b-form-group>  
                公告內容*
                <div ref="editortools" id="home" ></div>
                <div style="padding:5px;" />
                <p><a-button class="bton" size="large" v-show="vshow" @click="onSubmit" type="primary" shape="round" :disabled="!formdata" block :loading="iconLoading">建立公告</a-button></p>
                <p><a-button class="bton" size="large" v-show="!vshow" @click="onSubmit" type="primary" shape="round" :disabled="!formdata" block :loading="iconLoading">修改公告</a-button></p>
            </b-form>   
                         
            </div>

                            
            </b-card-text>
            </b-card-body>
            </b-card>            
            
    </div>
</template>

<script>
import { cmessService } from '../../../_services'
import {mapState} from 'vuex';
import E from "wangeditor";

const postnew = obj => {
  return  cmessService.postnew(obj)
}

const updata = obj => {
  return  cmessService.updata(obj)
}

export default {
    name: 'Createdecs',    
    props:{
          page:{
              type:Number
          },
        },    
    data(){
        return {
            errors:'',
            mtyps:'error',
            uploadurl:process.env.VUE_APP_URL +'user/edimage', 
            iconLoading: false,
            vshow:true,
            editor: null,
            editorData: '',  
            typedata :[{id:1,typ:'alert',name:'訊息'},{id:2,typ:'mess',name:'公告'}],       
            form: {    
                 id:'',            
                 type:'',
                 title:'', 
                 pid:'',
                 states:'',
                 createdAt:'',        
                }, 
            pagination:{
                results:'',
                page:'',
                pageSize: '',
                pid:'' 
                }
        }
    },
    computed:{
      ...mapState('account',['status','user']),
    
          
      formdata(){    
          if(this.form.title!=='' && this.editorData.length>0 && this.form.type!==''){
            //this.$emit('done',2)                        
            return true
          }else{
              return false 
          }
      },
      titleState() {
        return this.form.title.length > 0 ? true : false
      },
      
      typeState() {
          return this.form.type!=='' ? true : false
      }      
           

    },    
    methods :{
      async onSubmit(event) {
            event.preventDefault()
            this.iconLoading = true 
            let obj={}
            if(this.vshow){
                obj={
                    id:'',
                    type : this.form.type,
                    title : this.form.title,
                    pid : this.user.data.user.id,
                    fname : this.user.data.user.username,
                    rmg : this.editorData, 
                    tomenss:[],
                    createdAt:''
                }                
                postnew(obj).then((data) =>{
                        if(data.id!==""){
                            this.inid(null)
                        }
                    });  
            }else{
                obj={
                    id:this.form.id,
                    type : this.form.type,
                    title : this.form.title,
                    pid : this.form.pid,
                    rmg : this.editorData, 
                    states : this.form.states,
                    tomenss:[],
                    createdAt: this.form.createdAt
                }    
                //console.log(obj.rmg)             
                updata(obj).then(({data}) =>{
                        if(data.id!==""){
                            this.inid(this.pagination)
                        }
                    });                  
            }
     
        },
        inid(rs){
            this.form.type=''
            this.form.title=''
            this.editor.txt.html('')
            this.iconLoading = false
            this.$emit('done',2,rs) 
        },
        injection(rs){
            this.form.id= rs.id
            this.form.type=rs.type
            this.form.title=rs.title
            this.form.pid= rs.pid
            this.form.states = rs.states
            this.editor.txt.html(rs.rmg)
            this.form.createdAt = rs.createdAt
            this.vshow=false

            this.pagination.results = rs.results
            this.pagination.page = rs.page
            this.pagination.pageSize = rs.results
            this.pagination.pid = rs.pid
        },
        clear(){
            this.vshow=true
            this.form.type=''
            this.form.title=''
            this.editor.txt.html('')
            this.iconLoading = false
        },
  
        getdata(){
            return this.form
        },
         
        
        onReset(event) {
                event.preventDefault()
                this.$nextTick(() => {

                })
            },              

    }, 
	mounted () {
        //this.locatorButtonPressed()
        //this.geolocation();

    var editor = new E(this.$refs.editortools);
        editor.config.uploadFileName = 'imgfile'
        editor.config.uploadImgServer = this.uploadurl
        editor.config.uploadImgMaxSize = 3 * 1024 * 1024
        editor.config.uploadImgMaxLength = 1
        editor.config.uploadImgHeaders = {
          "Authorization": "Bearer "+this.status.token,
          "Accept": 'text/x-json',     
        }        

        editor.config.onchange = (newHtml) => {
          this.editorData = newHtml
          //console.log("newHtml : ",newHtml)
        }
        editor.create();
        this.editor =  editor
    },
    beforeDestroy() {
        this.editor.destroy()
        this.editor = null
    }     

}
</script>
<style scoped>
.card-body{
    padding:5px 0px 5px 0px;
}

.card-3 {
    padding:20px;
    border-radius: 6px;
    text-align: left;
    border-width:0px;
    margin: 25px auto;
    border-style:solid;
    border-color:#e0dedc;
	transition: all .3s ease;
}



</style>

